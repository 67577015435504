class AlpineCopy {

  constructor() {
    Alpine.directive('copy', (el, { expression }, { cleanup }) => {
      // Save the current icon
      const icon = el.querySelector('i');
      const className = icon.className;
      // Define the handler function
      const handler = (e) => {
        e.preventDefault();
        // Try to copy the text to the clipboard
        navigator.clipboard.writeText(expression).then(() => {
          icon.className = 'fa-solid fa-fw fa-circle-check text-success';
        }, () => {
          icon.className = 'fa-solid fa-fw fa-circle-exclamation text-danger';
        });
        // Set timeout to restore the icon
        setTimeout(() => {
          icon.className = className;
        }, 500);
      }
      // Add click listener and remove it on cleanup
      el.addEventListener('click', handler)
      cleanup(() => {
        el.removeEventListener('click', handler)
      })
    });
  }
}

class AlpineQr {

  constructor() {
    Alpine.directive('qr', (el, { expression }, { cleanup }) => {
      // Define the handler function
      const handler = (e) => {
        e.preventDefault();
        // Create a dynamic download button
        const svg = el.querySelector('svg').innerHTML;
        const blob = new Blob([ svg.toString() ]);
        const element = document.createElement('a');
        const name = expression || 'qr';
        element.download = `${name}.svg`;
        element.href = window.URL.createObjectURL(blob);
        element.click();
        element.remove();
      }
      // Add click listener and remove it on cleanup
      el.addEventListener('click', handler)
      cleanup(() => {
        el.removeEventListener('click', handler)
      })
    });
  }
}

class PopupPayPal {

  _el = null;

  _id = null;

  constructor(selector) {
    const el = $(selector);
    if (el.length) {
      const options = el.find('[name=amount]'),
        other = el.find('#amount-other'),
        custom = el.find('[name=custom]'),
        amount = el.find('.amount'),
        extra = el.find('.extra'),
        total = el.find('.total'),
        button = el.find('.btn-checkout');
      options.on('change', () => {
        const checked = options.filter(':checked');
        let quantity = checked.val();
        custom.prop('disabled', !other.prop('checked'));
        if (quantity == 'other') {
          quantity = custom.val();
        }
        let percent = (quantity * 0.05).toFixed(2),
          cost = Number(quantity) - Number(percent);
        amount.text(cost.toFixed(2));
        extra.text(percent);
        total.text(quantity);
        button.removeClass('disabled');
      });
      custom.on('change', () => {
        let quantity = custom.val();
        let percent = (quantity * 0.05).toFixed(2),
          cost = Number(quantity) - Number(percent);
        amount.text(cost.toFixed(2));
        extra.text(percent);
        total.text(quantity);
        button.removeClass('disabled');
      });
      button.on('click', (e) => {
        const id = this._id;
        e.preventDefault();
        button.addClass('d-none');
        options.prop('disabled', true);
        options.prop('custom', true);
        el.find('.checkout').removeClass('d-none');
        window.paypal.Buttons({
          async createOrder() {
            try {
              const response = await fetch(`${window.api.url}/orders/create`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  cart: [
                    {
                      id: id,
                      quantity: total.text(),
                    },
                  ],
                }),
              });
              const orderData = await response.json();
              if (orderData.id) {
                return orderData.id;
              } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ?`${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);
                throw new Error(errorMessage);
              }
            } catch (error) {
              console.error(error);
            }
          },
          async onApprove(data, actions) {
            try {
              const response = await fetch(`${window.api.url}/orders/${data.orderID}/capture`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              const orderData = await response.json();
              const errorDetail = orderData?.details?.[0];
              if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
                return actions.restart();
              } else if (errorDetail) {
                throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                $.magnificPopup.close();
                $.magnificPopup.open({
                  items: {
                    src: '#popup-error',
                    type: 'inline'
                  }
                });
              } else if (!orderData.purchase_units) {
                throw new Error(JSON.stringify(orderData));
                $.magnificPopup.close();
                $.magnificPopup.open({
                  items: {
                    src: '#popup-error',
                    type: 'inline'
                  }
                });
              } else {
                const transaction = orderData?.purchase_units?.[0]?.payments?.captures?.[0] || orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
                $.magnificPopup.close();
                $.magnificPopup.open({
                  items: {
                    src: '#popup-thanks',
                    type: 'inline'
                  }
                });
              }
            } catch (error) {
              console.error(error);
            }
          }
        }).render("#paypal-button-container");
      });
      this._el = el;
    }
  }

  show(id, title) {
    this._id = id;
    this._el.find('h3').text(title);
    this._el.find('.btn-checkout').removeClass('d-none').addClass('disabled');
    this._el.find('.checkout').addClass('d-none');
    this._el.find('#paypal-button-container').empty();
    this._el.find('.amount').text(0);
    this._el.find('.extra').text(0);
    this._el.find('.total').text(0);
    this._el.find('[name=amount]').prop('checked', false);
    this._el.find('[name=custom]').val(5);
    this._el.find('input').prop('disabled', false);
    $.magnificPopup.open({
      items: {
        src: this._el,
        type: 'inline'
      }
    });
  }
}

class App {

  _plugins = {};

  _popup = null;

  constructor(document, window) {
    document.addEventListener('alpine:init', () => {
      this._plugins.copier = new AlpineCopy();
      this._plugins.qr = new AlpineQr();
    });
    document.addEventListener('DOMContentLoaded', () => this.onDomReady(document), false);

    window.openDonationPopup = (e) => {
      let id = e.target.dataset.id,
        title = e.target.dataset.title;
      this._popup.show(id, title);
    };
  }

  onDomReady() {
    const swiper = new Swiper('.swiper', {
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });

    this._popup = new PopupPayPal('#popup-paypal');
  }
}

((d, w) => {
  w.app = w.app || new App(d, w);
})(document, window);
